
































































































.details
  padding 8px
  padding-left 0
.q-card
  height 70px
  margin 0px
  border-radius 0
  .q-card-subtitle
    color rgba(0,0,0,0.6)
.col.column
  width 8%
.icon-type
  left 8px
  top 80px
.date
  display flex
  justify-content space-between
.multi-date
  display flex
  flex-direction column

em
  color convert(brandColor)
  margin-right 8px
  font-weight 500

.flex-full
  flex-basis 100%
