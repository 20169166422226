































































































.nearby
  z-index 10
  padding 16px
  border-radius 16px
  padding-top 0px
  display grid
  grid-auto-rows auto
  grid-row-gap 24px
  align-items center

.three-grid
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-row-gap 16px

em
  color convert(brandColor)
