.item {
  height: 56px;
  border-bottom: 1px solid #8c8c8c;
}
.item .q-btn {
  width: 96px;
}
.topBar {
  height: 50px;
}
.mobilityNearbyList {
  z-index: 10;
  width: 800px;
  height: 350px;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  right: calc(50% - 400px);
}
.mobilityNearbyList .q-btn {
  font-size: 0.75em;
  padding: 2px 4px 2px 4px;
}
.loadingContainer .spinner {
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
}
@media only screen and (max-width: 800px) {
  .mobilityNearbyList {
    width: 100%;
    right: 0;
  }
}
/*# sourceMappingURL=src/pages/discovery/placeCard.css.map */