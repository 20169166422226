












































































































































.layout-padding
  max-width 1024px
.text-uppercase
  text-transform: text-uppercase
.mobile-header
  height: 30px
  padding: 0 5px
  font-size: 18px
  margin-bottom: 18px
.bookings
  font-size: 13px;
  letter-spacing: 1.5px;
  color: rgb(25, 118, 210)
.q-tab
  width 50%
