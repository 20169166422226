.q-inner-loading {
  background: transparent;
  height: 200px;
}
.layout-padding {
  max-width: 768px;
}
.bookingCard {
  background-color: #f6f6f6;
  box-shadow: none;
  padding: 0;
}
.q-card__section {
  padding: 8px;
}
.q-timeline__content,
.q-timeline__subtitle {
  padding: 0;
  margin: 0;
}
.empty .q-timeline__content,
.q-timeline__title,
.spacer .q-timeline__dot {
  display: none;
}
.q-timeline__heading-title {
  padding-bottom: 16px;
}
/*# sourceMappingURL=src/pages/journeys/show/timeline/timeline.css.map */