.type-buttons {
  flex-direction: row;
}
.type-filter-button {
  color: #406e8e;
  border: solid 1px #406e8e;
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 120px;
  padding: 5px;
  margin: 5px;
  border-radius: 15px;
}
.type-filter-button:active {
  transform: scale(0.95);
}
.type-filter-button.selected {
  background-color: #cfdae2;
}
.m-icon {
  margin-right: 5px;
}
/*# sourceMappingURL=src/pages/journeys/user-journeys/sidebar.css.map */