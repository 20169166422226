












































































.content-around
  position relative
  .q-inner-loading
    background: transparent
    height: 200px
.layout-padding
  max-width 768px
.q-card
  height: 100%
  max-width 768px
  &-media > img.missing
    background-color #eee

.photo
  width: 64px
  height: 92px
  vertical-align: top
  background-size: cover
  background-position: center
