

























































































































.type-buttons
  flex-direction row

.type-filter-button
  color convert(brandColor)
  border solid 1px convert(brandColor)
  background-color white
  display flex
  align-items center
  width 120px
  padding 5px
  margin 5px
  border-radius 15px
  &:active
    transform scale(0.95)
  &.selected
    background-color tint(convert(brandColor), 75)

.m-icon
  margin-right 5px
