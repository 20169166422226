.content-around {
  position: relative;
}
.content-around .q-inner-loading {
  background: transparent;
  height: 200px;
}
.layout-padding {
  max-width: 768px;
}
.q-card {
  height: 100%;
  max-width: 768px;
}
.q-card-media > img.missing {
  background-color: #eee;
}
.photo {
  width: 64px;
  height: 92px;
  vertical-align: top;
  background-size: cover;
  background-position: center;
}
/*# sourceMappingURL=src/pages/journeys/show/discovery/index.css.map */